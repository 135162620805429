import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";

export default function Publicidad() {
  return (
    <div>
      <Carousel controls-prev>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/firstBanner.png")}
            alt="First slide"
            style={{
              width: "100%",
              height: "40vh",
              padding: "1px 1px 0px 0.5px",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/secondBanner.png")}
            alt="Second slide"
            style={{
              width: "100%",
              height: "40vh",
              padding: "1px 1px 0px 0.5px",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/thirdBanner.png")}
            alt="Second slide"
            style={{
              width: "100%",
              height: "40vh",
              padding: "1px 1px 0px 0.5px",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/fourBanner.png")}
            alt="logo"
            style={{
              width: "100%",
              height: "40vh",
              padding: "1px 1px 0px 0.5px",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/fiveBanner.png")}
            alt="logo"
            style={{
              width: "100%",
              height: "40vh",
              padding: "1px 1px 0px 0.5px",
            }}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
