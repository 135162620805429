import React from "react";
import { Nav, NavLink, Bars, NavMenu } from "./NavbarElements";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Form, FormControl, Button, Image } from "react-bootstrap";

import Publicidad from "../Container/Publicidad";

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLink to="/">
          <Image
            style={{
              width: "88%",
            }}
            src={require("../../images/logoSiccpetr.png")}
            rounded
          />
        </NavLink>
        <Bars />
        <NavMenu
          style={{
            fontfamily: "Sora, sans-serif",
            boxSizing: "border-box",
            margin: "0",
            padding: "0",
          }}
        >
          <NavLink exact to="/" activeStyle>
            Inicio
          </NavLink>
          <NavLink to="/services" activeStyle>
            Servicios
          </NavLink>
          <NavLink to="/politics" activeStyle>
            Políticas
          </NavLink>
          <NavLink to="/appeals" activeStyle>
            Quejas y Apelaciones
          </NavLink>
          <NavLink to="/contact-us" activeStyle>
            Contactenos
          </NavLink>
          <Container>
            <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
              />
              <Button variant="primary">Search</Button>
            </Form>
          </Container>
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </NavMenu>
        {/* <NavBtn>
          <NavBtnLink to="/signin">Sign In</NavBtnLink>
        </NavBtn> */}
      </Nav>
      <Publicidad></Publicidad>
    </>
  );
};

export default Navbar;
